<template>
    <div>
        <app-page-header></app-page-header>
        <v-container grid-list-xl>
            <exports-data-table
            :settings="dataTableSettings"
            :queryParameters="queryParameters"
            ></exports-data-table> 

            <data-table-toggle-columns
            :dataTableId="dataTableSettings.id"
            :columns="dataTableSettings.headers"
            :dataTableColumnsMenuId="dataTableSettings.eventBus.toggleColumnsId"

            ></data-table-toggle-columns>
        </v-container>
    </div>
</template>

<script>
import { EventBus } from '@/main.js'
import { getCurrentDate, getCurrentTime, formatDate, getAppSettingsFromLocalStorage } from '@/helpers/utilities'
const DataTableToggleColumns = () => import('@/components/DataTableToggleColumns.vue')
const ExportsDataTable = () => import('@/components/ExportsDataTable.vue')
import { isMobileDevice } from '@/helpers/utilities.js'

export default {
    components: {
        ExportsDataTable,
        DataTableToggleColumns
    },

    data: () => ({
        dataTableSettings: {
            id: 'dtExports',
            title: 'List of documents',
            sortColumn: ['id'],
            sortDirection: ['desc'],
            progressHeight: 4,
            headers: [
                { text: "ID", value: "id", class: "text-xs-left", visibility: true},
                { text: "Document name", value: "name", class: "text-xs-left", visibility: true},
                { text: "Status", value: "export_status_name", class: "text-xs-left", visibility: true},
                { text: "File type", value: "file_type_for_humans", class: "text-xs-left", visibility: true},
                { text: "File size", value: "file_size_readable", class: "text-xs-left", visibility: true},
                { text: "Task progress", value: "done", class: "text-xs-left", visibility: true},
                { text: "Memory usage", value: "memory_peak_usage", class: "text-xs-left", visibility: true},
                { text: "Duration", value: "execution_time_for_humans", class: "text-xs-left", visibility: true},
                { text: "Data group", value: "data_group", class: "text-xs-left", visibility: true},
                { text: "Owner", value: "user", class: "text-xs-left", visibility: true},
                { text: "Export timestamp", value: "created_at_formatted", class: "text-xs-left", visibility: true},
                { text: "Action", value: "action", align: "right", class: "text-xs-right", sortable: false, visibility: true, permissions:[]},
            ],
            updateInterval: true,
            rowsPerPage: 10,
            endpoint: 'v1/exports',
            downloadItem: true,
            viewItem: {
                route: 'view_user',
                routeParameterValue: 'uuid'
            },
            deleteItem: {
                vuexAction: 'DELETE_FILE',
                replaceStrWithColumnValue: 'name',
                routeParameterValue: 'uuid',
                confirmationText : "Are you sure you want to delete document {str_to_replace} ?",
                dialogConfirmSuccessText: 'Document {str_to_replace} deleted.',
                dialogConfirmFailText: 'An error occurred while deleting the document {str_to_replace}. Try again.'
            },

            toolbar: {
                buttons: {
                    refresh: true,
                    export: false,
                    addNew: false,
                    addNewRoute: null,
                    toggleColumns: true
                }
            },

            eventBus: {
                refreshDataTable: 'refresh_dt_exports',
                totalItems : {
                    event: ''
                },
                search: 'dt-exports-search',
                toggleColumnsId: 'toggle-exports-list-columns'
            }


        }
    }),

    computed: {
        queryParameters() {
            return {
                startDate: null,
                endDate: null,
                startTime: null,
                endTime: null
            }
        },
    },

    methods: {
        search() {
            setTimeout(() => {
                EventBus.$emit(this.dataTableSettings.eventBus.refreshDataTable, 'pass-some-data');
            }, 250)
        },

        displayResults() {
            setTimeout(() => {
                EventBus.$emit(this.dataTableSettings.eventBus.refreshDataTable, 'pass-some-data');
            }, 280)
        },

        setParametersData() {
            var vm = this

            if(this.$store.getters.parametersData == null) {
                window.console.log("a")
                vm.$store.commit('SET_PROGRESS', true)
                vm.$store.dispatch('PARAMETERS_DATA')
                .then(response => {
                    //console.log(response)
                    //console.log(response.data)
                })
                .catch(error => {
                    console.error("### setParametersData ###");
                    console.log(error)
                })
                .finally(() => {
                    setTimeout(() => {
                        vm.$store.commit('SET_PROGRESS', false)  
                    }, 700)
                })
            } else {
                window.console.log("b")
            }

        },
    },

    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name) 
        let ipp = getAppSettingsFromLocalStorage({
            setting: "dt_exports",
            key: "items_per_page"
        })

        if(ipp !== null) {
            this.dataTableSettings.rowsPerPage = ipp
        }
        // window.console.log("### Exports@created ###");

        //this.$store.dispatch('PARAMETERS_DATA');
        this.setParametersData();

    },

    mounted() {

    },

    beforeDestroy() {
        EventBus.$off(this.dataTableSettings.id)
    }
}

</script>